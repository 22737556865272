/** @jsxImportSource theme-ui */

import * as React from "react"
import { Disqus } from "gatsby-plugin-disqus"

export interface DisqusProps {
  url: string
  identifier: string
  title: string
}
const DisqusComments: React.FC<DisqusProps> = ({ url, identifier, title }) => {
  /* Page contents */

  return (
    <Disqus
      config={{
        /* Replace PAGE_URL with your post's canonical URL variable */
        url,
        /* Replace PAGE_IDENTIFIER with your page's unique identifier variable */
        identifier,
        /* Replace PAGE_TITLE with the title of the page */
        title,
      }}
    />
  )
}

export default DisqusComments
